.menu.large {
  margin-top: 0px !important;
  padding-top: 17px;
  padding-bottom: 17px;
  z-index: 999;
  position: relative;
  border-radius: 0 !important;
  margin-bottom: 0 !important;

  .logo {
    width: 158px !important;
  }

  .item {
    padding: 0.92857143em 1.14285714em !important;
  }

  button {
    border-radius: 10px;
    background: #4B5DC5;
    color: #FFF;
  }

  &.secondary {
    .item {
      color: #FFF !important;
    }

    button {
      background: #FFF;
      color: #141929;
    }
  }
}