
$gradient: radial-gradient(26.32% 53.26% at -3.4% 51.07%, #1B2340 27.38%, rgba(27, 35, 64, 0) 100%), radial-gradient(24.81% 45.2% at 42.36% 95.2%, #1B2340 36.07%, rgba(27, 35, 64, 0) 100%), radial-gradient(39.23% 68.38% at 18.72% 73.09%, #1B2340 46.68%, rgba(27, 35, 64, 0) 100%), radial-gradient(51.61% 78.81% at 29.3% 21.14%, #FF8D74 0%, rgba(255, 141, 116, 0) 100%), radial-gradient(27.56% 50.2% at 68.19% -4.53%, #1B2340 29.63%, rgba(27, 35, 64, 0) 100%), radial-gradient(44.31% 80.73% at 69.69% 87.47%, #1B2340 39.5%, rgba(27, 35, 64, 0) 100%), radial-gradient(48.28% 103.28% at 92.13% 4.93%, #3e4982 0%, rgba(116, 129, 216, 0) 100%), #1B2340;

$img: url('../../assets/philly_bg.png');

.landing {
  position: relative;
  width: 100%;
  height: 100%;
  color: #fff;

  // Image
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: $img;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    mix-blend-mode: overlay;
    pointer-events: none;
    z-index: -1;
  }

  // Gradient
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $gradient no-repeat center;
    background-size: cover;
    min-width: 900px;
    z-index: -2;
  }

  .ui.container {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .logo {
    width: 20em;
    margin-bottom: 100px;
    position: absolute;
    top: 50px;
  }

  .content {
    h1.header {
      font-family: 'Plus Jakarta Display';
      font-style: normal;
      font-weight: 500;
      font-size: 64px;
      line-height: 130%;
      width: 720px;
      margin-bottom: 30px;
      max-width: 100%;
    }

    .text {
      font-size: 22px;
      width: 600px;
    }
  }

}
