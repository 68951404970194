@import './src/semantic-ui/gradients';
.quality-scale-card {
  display: flex;
  flex-direction: column;
  height: 100%;
  // .bar {
  //   width: 100%;
  //   height: 20px;
  //   border-radius: 10px;
  //   margin-top: 2rem;
  //   background: linear-gradient(to left, $grade_a, $grade_b, $grade_c, $grade_d, $grade_f);
  //   position: relative;

  //   &::before {
  //     content: '';
  //     position: absolute;
  //     top: 0;
  //     left: var(--dash-position);
  //     height: 100%;
  //     width: 2px;
  //     background-color: #1B2340;
  //     transform: translateX(-50%);
  //     transition: left 0.3s ease-in-out;
  //   }

  //   &:hover .tooltip {
  //     visibility: visible;
  //     opacity: 1;
  //     transition-delay: 0s;
  //   }
  // }

  // .tooltip {
  //   visibility: hidden;
  //   opacity: 0;
  //   transition: opacity 0.3s, visibility 0.3s 0.3s;
  //   position: absolute;
  //   top: -60px;
  //   left: 0;
  //   width: 7.5rem;
  //   padding: 1em;
  //   border-radius: 15px;
  //   background-color: #1B2340;
  //   color: white;
  //   text-align: center;
  //   transform: translateX(-50%);
  //   z-index: 999;

  //   &::after {
  //     content: '';
  //     position: absolute;
  //     top: 100%;
  //     left: 50%;
  //     transform: translateX(-50%);
  //     border: 10px solid transparent;
  //     border-top-color: #1B2340;
  //     border-bottom: none;
  //   }

  //   .tooltip-primary-text {
  //     margin-bottom: 5px;
  //     font-size: 18px;
  //     font-weight: 800;
  //     color: #FFFFFF;
  //   }

  //   .tooltip-secondary-text {
  //     font-size: 14px;
  //     color: #C6CCE9;
  //   }
  // }

  .grade-icon {
    position: absolute;
    top: 1.3rem;
    right: 2rem;
    width: 55px;
    height: 55px;
    background-color: #1B2340;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 30%;
    font-size: 25px;
    font-weight: bold;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);

    &.grade-a {
      background-color: $grade_a;
    }

    &.grade-b {
      background-color: $grade_b;
    }

    &.grade-c {
      background-color: $grade_c;
    }

    &.grade-d {
      background-color: $grade_d;
    }

    &.grade-f {
      background-color: $grade_f;
    }
  }
}