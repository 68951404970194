.contact {
  padding-top: 150px;
  padding-bottom: 150px;
  background: linear-gradient(195.05deg, #C6CCE9 8.53%, #E2E5F4 19.59%, rgba(242, 244, 251, 0.890625) 37.37%, rgba(242, 244, 251, 0) 89.4%);

  .contactCard {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    max-width: 600px;
    padding: 30px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07), 0px 14px 48px rgba(0, 0, 0, 0.08);
    border-radius: 20px;

    label {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 24px;
      color: #000000;
    }

    ::placeholder, i {
      color: #7F859C !important;
    }

    .input {
      height: 48px;
    }

    input, textarea {
      background: #F2F4FB !important;
      border-radius: 6px !important;
      color: #7F859C;
      border: none !important;
    }

    button {
      border-radius: 10px;
      background: #4B5DC5;
      color: #FFF;
      height: 54px;
    }

    .decoration {
      position: absolute;
      left: -40px;
      top: -46px;
    }
  }
}