.tooltip {
  background-color: #1B2340;
  color: white;
  text-align: center;
  border-radius: 15px;
  padding: 1.5em;
}

.tooltip-primary-text {
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 800;
  color: #FFFFFF;
}

.tooltip-secondary-text {
  font-size: 14px;
  color: #C6CCE9;
}