.building-viewer {
    .header {
        a {
            color: #000;
            display: flex !important;
            align-items: center !important;
            align-content: center !important;
            span {
                margin-right: 10px !important;
            }
            .icon {
                height: 100% !important;
            }
            &:hover {
                color: #0062ff;
            }
        }
        .list {
            .item {
                font-size: 14px !important;
            }
        }
    }
}