// COLORS
$red: #FF8D74;
$green: #82B26C;
$yellow: #FFD700;
.insights-box {
    border-radius: 10px !important;
    padding: 10px !important;
    background-color: #387CFF !important;
    display: flex !important;
    margin-top: 20px !important;
    .icon-container {
        padding: 5px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 55px;

        img {
            height: 37px;
            width: 37px;
        }
    }
    .textbox-container {
        margin-left: 1.5rem;
        color: #fff !important;
        font-weight: 900;
        font-style: italic;
        font-size: 16px;
    }
}

.rating-gs {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    font-size: 14px;
    border: 2px solid #1B2340;
    border-radius: 5px;
    padding: 5px 10px;

    &.red {
        border-color: $red;
        color: $red;
    }
    &.green {
        border-color: $green;
        color: $green;
    }
    &.yellow {
        border-color: $yellow;
        color: $yellow;
    }
}
