/*******************************
         Site Overrides
*******************************/

.menu .active.item {
  color: @selectedTextColor !important;
  i {
    color: #4B5DC5;
  }
}

.menu .item {
  color: #999EB7 !important;
}

.menu .timeseriesMenuItem.active {
  color: #36459E !important;
  background-color: #F2F4FB;
}

.ui.vertical.menu .item:before {
  background: none;
}