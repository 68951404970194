.footer {
  background-color: #141929;
  height: 120px;

  p {
    line-height: 120px;
    vertical-align: middle;
    color: #8D94AE;
    font-size: 15px;
    font-family: Inter;
  }
}