// COLORS
$red: #FF8D74;
$green: #82B26C;
$yellow: #FFD700;

.on-market-rate-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0em 0em 1em 0em !important;
  
    .content {
      display: flex !important;
      flex-direction: column !important;
      justify-content: space-between !important;
  
      .link {
        margin-top: 10px;
      }
  
      .content-container {
        display: flex;
        align-items: center;
        margin-top: 5px;
        .time-metric {
          width: 100px;
          height: 45px;
          background-color: #1B2340;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 10px;
          font-size: 18px;
          font-weight: bold;
          box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
          margin-right: 1rem;
        }
    
        .rating {
          font-size: 14px;
          border: 2px solid #1B2340;
          border-radius: 5px;
          padding: 5px 10px;
  
          &.red {
            border-color: $red;
            color: $red;
          }
          &.green {
            border-color: $green;
            color: $green;
          }
          &.yellow {
            border-color: $yellow;
            color: $yellow;
          }
        }
      }
    }
    
  }