/*******************************
         Site Overrides
*******************************/

#root {
  width: 100%;
  height: 100%;
}

// Nav Clearance
.ui.container.content {
  margin-left: 210px !important;
  width: calc(100% - 210px) !important;
  padding: 40px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}