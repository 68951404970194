/*******************************
         Site Overrides
*******************************/

@import '../globals/site.variables';


// Fill in circle with icon color

@circleOpacity: 0.15;

i.circular.icon.red {
  box-shadow: 0em 0em 0em 1em rgba(@red, @circleOpacity) inset;
}
i.circular.icon.orange {
  box-shadow: 0em 0em 0em 1em rgba(@orange, @circleOpacity) inset;
}
i.circular.icon.yellow {
  box-shadow: 0em 0em 0em 1em rgba(@yellow, @circleOpacity) inset;
}
i.circular.icon.olive {
  box-shadow: 0em 0em 0em 1em rgba(@olive, @circleOpacity) inset;
}
i.circular.icon.green {
  box-shadow: 0em 0em 0em 1em rgba(@green, @circleOpacity) inset;
}
i.circular.icon.teal {
  box-shadow: 0em 0em 0em 1em rgba(@teal, @circleOpacity) inset;
}
i.circular.icon.blue {
  box-shadow: 0em 0em 0em 1em rgba(@blue, @circleOpacity) inset;
}
i.circular.icon.violet {
  box-shadow: 0em 0em 0em 1em rgba(@violet, @circleOpacity) inset;
}
i.circular.icon.purple {
  box-shadow: 0em 0em 0em 1em rgba(@purple, @circleOpacity) inset;
}
i.circular.icon.pink {
  box-shadow: 0em 0em 0em 1em rgba(@pink, @circleOpacity) inset;
}
i.circular.icon.brown {
  box-shadow: 0em 0em 0em 1em rgba(@brown, @circleOpacity) inset;
}
i.circular.icon.grey {
  box-shadow: 0em 0em 0em 1em rgba(@grey, @circleOpacity) inset;
}
i.circular.icon.black {
  box-shadow: 0em 0em 0em 1em rgba(@black, @circleOpacity) inset;
}
