.ai-analyst-container {
    background-color: #ABECFD !important;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .icon-container {
        padding: 5px;
        margin-right: 10px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        background-color: #fff !important;
        border: 2px solid #fff !important;
        border-radius: 10px;
        width: 180px;
        cursor: pointer;

        &.small {
            width: 55px !important;

            &:hover {
                background-color: #fff !important;
                border: 2px solid #fff !important;
            }
        }

        &:hover {
            background-color: #f9f9f9 !important;
            border: 2px solid #f9f9f9 !important;
        }

        img {
            height: 37px;
            width: 37px;
        }
        span {
            font-weight: 600;
        }
    }
    .message-container {
        margin-left: 1.5rem;
    }
}