// Feature Text
.vcenter {
  display: flex !important;
  align-items: center;
}

.feature {
  p {
    margin: 0em 0em .5em;
  }
  .catch {
    font-size: 14px;
    font-family: 'Plus Jakarta Display';
    font-weight: 600;

    &.green {
      color: #82B26C;
    }
    &.purple {
      color: #4B5DC5;
    }
    &.pink {
      color: #BE59BA;
    }
    &.orange {
      color: #EC8E37;
    }
  }
  .title {
    font-size: 33.16px;
    color: #000;
    font-family: 'Plus Jakarta Display';
  }
  .body {
    color: #484B58;
    font-size: 16px;
    line-height: 28px;
  }
  img {
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
}


.subFeature {
  margin-top: 75px;

  .iconContainer {
    height: 100%;
    width: 70px;
    display: inline-block;
    vertical-align: top;

    .iconBox {
      background: #F2F4FB;
      border-radius: 10px;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .contentContainer {
    display: inline-block;
    width: calc(80% - 70px);

    .title {
      font-size: 18px;
      line-height: 23px;
      color: #000;
    }

    .body {
      font-size: 15px;
      line-height: 24px;
      color: #484B58;
    }
  }
}

// Features Container
.features {
  margin-bottom: 100px;
}