$grades: (
  'A': rgba(33, 186, 69, 0.2),
  'B': rgba(181, 204, 24, 0.2),
  'C': rgba(251, 189, 8, 0.2),
  'D': rgba(242, 113, 28, 0.2),
  'F': rgba(219, 40, 40, 0.2),
);

.rent-table {
  &:hover:not(.empty) {
    cursor: pointer;
  }

  @each $grade, $color in $grades {
    &.#{$grade}-bg {
      background-color: $color;
    }
  }
}
