// Defaults
body {
  font-family: 'Inter';
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: 1.555;
  color: #353846;
  background: #FFF;
}


.dashboardContainer {
  display: flex !important;
  @media (max-width: 740px) {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .dashboardLeft {
    width: 800px;
    margin-right: 2rem;
    @media (max-width: 740px) {
      margin: 0px !important;
    }
  }
  .dashboardRight {
    @media (max-width: 1200px) {
     display: none !important;
    }
    flex: 1;
  }
}



// Dashboard
.main-container {

  padding-bottom: 4rem;

  .mapboxgl-ctrl-logo,
  .mapboxgl-ctrl-attrib-inner,
  .mapboxgl-ctrl-bottom-right {
      display: none !important;
  }

  .stat-card {
    height: 184px;

    .graph {
      height: 300px;
      padding-top: 1rem !important;
      padding-right: 1rem !important;
    }
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
}

// New
.card .content {
  padding: 2em !important;

  .header {
    font-size: 1.7em !important;
  }
}

.equal-height-grid {
  display: flex;
  .column {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}