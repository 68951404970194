.mapMenu {
  border-radius: 0 !important;
  padding: 1rem;
  @media (max-width: 1000px) {
    flex-direction: column;
  }
  @media (max-width: 740px) {
    padding: 0px !important;
    padding-top: 10px !important;
  }
  .buttons-grid {
    display: grid !important;
    grid-template-rows: auto;  
    grid-auto-flow: column;
    gap: 10px;
    @media (max-width: 740px) {
      grid-template-rows: repeat(2, 1fr);
      grid-template-columns: repeat(2, 1fr);
    }
    .button {
      height: 45px !important;
      text-align: center;
      @media (max-width: 740px) {
        margin-bottom: 10px !important;
        margin-right: 0px !important;
      }
    }
    &:before {
      display: none;
    }
  }
  .item {
    border: none !important;
    &.searchItem {
      @media (min-width: 1000px) and (max-width: 1300px) {
        width: 300px;
      }
      @media (max-width: 1000px) {
        width: 100%;
      }
      width: 600px;
    }
  }
}