$grade_green: rgb(33,186,69);
$grade_olive: rgb(181,204,24);
$grade_yellow: rgb(251,189,8);
$grade_orange: rgb(242,113,28);
$grade_red: rgb(219,40,40);

$grade_a: $grade_green;
$grade_b: $grade_olive;
$grade_c: $grade_yellow;
$grade_d: $grade_orange;
$grade_f: $grade_red;