// Hero
.hero {
  // CANCEL OUT NAVBAR, SEE styles.scss
  margin-top: -87px !important;

  position: relative;
  width: 100%;

  /* Dynamic height */
  padding: 224px 0 100px;

  /* Move it up by the height of the header */
  margin-top: -40px;
  background:
          linear-gradient(180deg,rgba(255, 255, 255, 0) 42.99%, #FFFFFF 100%),
          linear-gradient(142.43deg, rgba(255, 255, 255, 0.85) 2.99%, rgba(255, 255, 255, 0) 27.38%),
          radial-gradient(45.75% 45.31% at 76.11% 80.13%, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%),
          linear-gradient(190deg, #8B98E3 8.69%, #B2BAEE 19.64%, #D0D5F5 35.55%, #E4E7FC 49.18%, #F8F9FF 62.1%, #FFFFFF 87.76%);
  overflow: hidden;

  h1 {
    font-family: 'Plus Jakarta Display';
    font-size: 74px;
    line-height: 1.2;
    font-weight: 600;
    color: #040404;
    margin: 0;
  }

  .container {
    position: relative;
    z-index: 1;

    &.burn {
      mix-blend-mode: color-burn;
      margin-bottom: 24px;
    }
  }

  .hero-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    min-height: 820px;
    left: 0px;
    top: 0px;
    font-size: 0;

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top;
      mix-blend-mode: color-burn;
      pointer-events: none;
    }
  }
}