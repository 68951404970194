.filterButton {
  background-color: rgba(#FFF, 0.05) !important;
  border: 1px solid rgba(#FFF, 0.2) !important;
  color: rgba(#FFF, 0.9) !important;
  margin-right: 15px !important;
  height: 100%;

  img, span, i {
    vertical-align: middle !important;
    display: inline-block !important;
  }

  span {
    font-size: 14px;
  }

  img {
    width: 20px;
    margin-right: 5px;
  }

  &.active {
    border: 1px solid rgba(#FFF, 1) !important;
    color: rgba(#FFF, 1) !important;
  }
}
.dropdown-menu {
  margin-top: 5px !important;
  min-width: 350px !important;

  .item:first-child {
    height: 50px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;

    span {
      font-weight: bold !important;
      font-size: 15px;
    }
  }
}
.clear-button {
  display: flex !important;
}