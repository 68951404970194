$blue: #387CFF;
$grey: rgba(#000, 0.75);

.tabmenu {
  margin: 0 !important;
  background-color: #fff !important;
  padding-left: 2em;
  padding-right: 2em;
  border-bottom: none !important;
  font-size: 1.1rem !important;

  @media (max-width: 740px) {
    padding: 0px !important;
  }

  .item.tabmenu__item {
    padding: 1.25em !important;
    color: $grey !important;
    font-weight: 500 !important;
    border-bottom: 3px solid #fff !important;

    &.active {
      font-weight: 600 !important;
      color: $blue !important;
      border-color: $blue !important;
      border-width: 3px !important;
    }

    &:hover {
      color: #000 !important;
      border-bottom: 3px solid #000 !important;
    }
  }
}