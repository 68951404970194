/*******************************
     User Global Variables
*******************************/

@headerFontWeight: normal;

@red              : #DB2828;
@orange           : #F2711C;
@yellow           : #FBBD08;
@olive            : #B5CC18;
@green            : #21BA45;
@teal             : #00B5AD;
@blue             : #2185D0;
@violet           : #6435C9;
@purple           : #4B5DC5;
@pink             : #E03997;
@brown            : #A5673F;
@grey             : #F2F4FB;
@black            : #1B1C1D;

@p900: #1B2340;
@p1000: #141929;

@primaryColor: #4B5DC5;

@pageBackground: #F4F5F9;

@importGoogleFonts : false;

@font-face {
    font-family: 'Plus Jakarta Display';
    src: url(../../../assets/fonts/PlusJakartaDisplay-Light.woff2) format('woff2'), url(../../../assets/fonts/PlusJakartaDisplay-Light.woff) format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Plus Jakarta Display';
    src: url(../../../assets/fonts/PlusJakartaDisplay-Regular.woff2) format('woff2'), url(../../../assets/fonts/PlusJakartaDisplay-Regular.woff) format('woff');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Plus Jakarta Display';
    src: url(../../../assets/fonts/PlusJakartaDisplay-Medium.woff2) format('woff2'), url(../../../assets/fonts/PlusJakartaDisplay-Medium.woff) format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Plus Jakarta Display';
    src: url(../../../assets/fonts/PlusJakartaDisplay-Bold.woff2) format('woff2'), url(../../../assets/fonts/PlusJakartaDisplay-Bold.woff) format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400;600;700;800&display=swap');

@fontName          : 'Inter';
@fontSmoothing     : antialiased;
@headerFont        : 'Plus Jakarta Display';
@headerFontWeight : 500;
@pageFont          : @fontName;

@defaultBorderRadius: 7px;
@absoluteBorderRadius: 7px;