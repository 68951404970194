$font_size: 16px;

.searchInput {
  display: inline-block !important;
  width: 300px;
}

.searchButton {
  display: inline-block !important;
  height: 45px;
  margin-left: 10px !important;
  background-color: #FF8D74 !important;
  color: #FFF !important;
  font-weight: 800 !important;
  font-size: $font_size !important;
}

.searchForm {
  margin-top: 50px;
  font-size: $font_size !important;
}