.property-viewer {
    .header {
        position: relative;
    }
    .property-viewer-label {
        align-self: center !important;
        height: 35px !important;
        width: 130px !important;
        display: flex !important;
        align-items: center !important;
        justify-content: center;
    }
    .vertical-divider {
        border-left: 1px solid #e7e7e7;
        height: 100%;
        margin: 0 10px;
    }
    .no-padding-top {
        padding-top: 0 !important;
    }
    .zillow_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            height: 20px;
            width: 20px;
            margin-left: 10px;
        }
    }
    .back-button {
        position: absolute;
        top: 50%;
        right: 0;
        transform: translate(-50%,-50%);
        z-index: 1;
    }
    .lease-table-wrapper {
        border-bottom: 1px solid rgba(34, 36, 38, 0.15) !important;
        padding-bottom: 2rem;
        margin: 0 -1.5rem !important;
        .header {
            margin-bottom: 2rem;
        }
    }
    .lease-table {
        padding: 0 1.5rem !important;
    }
}
