.properties-table-container {
    .property-row {
        cursor: pointer;
        .label {
            margin-left: 5px !important;
        }
    }
    
    padding: 2rem;

    .grade-bubble {

        display: flex;
        justify-content: center;

        div {
            display: inline-block;
            width: 24px;
            height: 24px; 
            border-radius: 50%; 
            position: relative;

            span {
                position: absolute; 
                top: 50%; 
                left: 50%; 
                transform: translate(-50%, -50%); 
                font-weight: bold; 
                color: white;
                font-size: 13px;
            }
        }
        
    }

    .pagination-wrapper {

        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 1rem;
    
        .custom-pagination {
            background: inherit !important;
            border: unset !important;
            box-shadow: unset !important;
        
            .active.item {
                display: none !important;
            }
        
            .item {
                color: black !important;
                border-radius: 30px !important;
                padding: 0px 0px 3px 17px !important;
    
                &::before {
                    all: unset !important;
                }
            }
        }
    
        .page-range-display {
            margin-right: 2rem;
        }
    
        .rows-per-page-selector {
            display: flex;
            align-items: baseline;
            margin-right: 2rem;
        
        }
    
    }
}
